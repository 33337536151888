import Image from "next/image";
import AnimatedCapsule from "../../common/ui/AnimatedCapsule";
import CrossIcon from "../../icon/CrossIcon";
import MarkIcon from "../../icon/MarkIcon";
import TitleHome from "./TitleHome";

const Compare = () => {
    const titleInfo = {
        button: {
            btn_title: "Start Challenge Now",
            btn_link: "/about-us"
        },
        title: {
            text: "Why Choose FundedNext",
            customStyle: ""
        },
        description: {
            text: "Explore the features that make FundedNext stand out.",
            customStyle: " xl:w-8/12 mx-auto"
        },
        customStyle: "w-10/12 mx-auto"
    };

    const compareInfo = [
        {
            variable: "VARIABLES",
            fundednext: (
                <Image
                    src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/1.svg"
                    alt=""
                    width={180}
                    height={50}
                />
            ),
            FTMO: (
                <Image
                    src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/2.svg"
                    alt=""
                    width={180}
                    height={50}
                />
            ),
            FP: (
                <Image
                    src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/4.svg"
                    alt=""
                    width={180}
                    height={50}
                />
            ),
            AC: (
                <Image
                    src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/3.svg"
                    alt=""
                    width={180}
                    height={50}
                />
            )
        },
        {
            variable: "Profit Share from Challenge Phase",
            fundednext: "15%",
            FTMO: "0%",
            FP: "0%",
            AC: "0%"
        },
        {
            variable: "Account Profit Share",
            fundednext: "80% | 95% with Add-on",
            FTMO: "80%",
            FP: "80%",
            AC: "80%"
        },
        {
            variable: "Profit Target",
            fundednext: "8%/5%",
            FTMO: "10%/5%",
            FP: "8%/5%",
            AC: "8%/5%"
        },
        {
            variable: "Drawdown",
            fundednext: "Balance-Based",
            FTMO: "Balance-Based",
            FP: "Equity-Based",
            AC: "Balance-Based"
        },
        {
            variable: "Account Reset Option",
            fundednext: <MarkIcon />,
            FTMO: <CrossIcon />,
            FP: <CrossIcon />,
            AC: <CrossIcon />
        },
        {
            variable: "One Step Challenge",
            fundednext: <MarkIcon />,
            FTMO: <CrossIcon />,
            FP: <MarkIcon />,
            AC: <CrossIcon />
        },
        {
            variable: "Free Competitions",
            fundednext: <MarkIcon />,
            FTMO: <CrossIcon />,
            FP: <CrossIcon />,
            AC: <MarkIcon />
        },
        {
            variable: "Global Meetup",
            fundednext: "5 Countries",
            FTMO: "0",
            FP: "0",
            AC: "0"
        },
        {
            variable: "Jurisdiction",
            fundednext: "UAE",
            FTMO: "Czech Republic",
            FP: "UAE",
            AC: "UK"
        },
        {
            variable: "Lowest Package with Price",
            fundednext: "5K/$32",
            FTMO: "10K/$155",
            FP: "5K/$36",
            AC: "10K/$97"
        },
        {
            variable: "Challenge Fee Reward",
            fundednext: "1st Withdrawal",
            FTMO: "1st Withdrawal",
            FP: "4th Withdrawal",
            AC: <CrossIcon />
        }
    ];

    const renderSingleColMobile = (value, index, borderTrue) => {
        if (index === 0) {
            return (
                <div
                    key={index}
                    className=" flex h-[30px]  w-full justify-center"
                >
                    {value}
                </div>
            );
        } else
            return (
                <div
                    key={index}
                    className={`relative w-full  ${index === 1 || index === 2 || index === 5 || index === 10 || index === 11 ? "min-h-[40px]" : index === 7 ? "min-h-[20px]" : ""} flex items-center justify-center text-sm text-white`}
                >
                    {borderTrue && (
                        <img
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/dottent-border.svg"
                            alt=""
                            className="absolute left-[15px] top-0"
                        />
                    )}{" "}
                    {/* {borderTrue && (
                        <img
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/dottent-border.svg"
                            alt=""
                            className="absolute right-[-22px] top-0"
                        />
                    )} */}
                    <p className="w-full px-5 text-center">{value}</p>
                </div>
            );
    };

    const renderLargeScreen = () => {
        return (
            <>
                <div className="hidden flex-col pt-[60px] lg:flex">
                    {compareInfo.map((item, index) => {
                        return (
                            <div
                                className={`relative flex flex-wrap items-center px-[30px]   
                                    ${index === 0 ? "rounded-t-[20px] bg-[#231735] py-[10px]" : "group bg-[linear-gradient(104deg,rgba(255,255,255,0.10)_3.55%,rgba(255,255,255,0.06)_96.44%)] transition-all duration-150 hover:bg-[#231735d1]"}
                                    ${index === compareInfo?.length - 1 ? "rounded-b-[20px]" : ""}

                                    `}
                                key={index}
                            >
                                <p
                                    className={`flex w-[200px] items-center justify-start text-sm text-white xl:w-[250px]  ${index === 0 ? "font-medium tracking-[1.68px]   opacity-40" : " py-[15px] font-normal"}`}
                                >
                                    {item?.variable}
                                </p>
                                {index !== 0 ? (
                                    <div className="flex w-[calc(100%-200px)] items-center text-sm lg:text-[13px] xl:w-[calc(100%-250px)]  xl:text-sm">
                                        <p
                                            className="flex w-3/12 items-center justify-center border-l-[1px] border-dashed border-[rgba(255,255,255,0.06)]
                                            
                                            py-[15px]  text-white
                                            "
                                        >
                                            {item?.fundednext}
                                        </p>
                                        <p className="flex w-3/12 items-center justify-center border-l-[1px] border-dashed border-[rgba(255,255,255,0.06)] py-[15px]   text-white">
                                            {item?.FTMO}
                                        </p>
                                        <p
                                            className="flex w-3/12 items-center justify-center
                                            
                                            border-l-[1px] border-dashed border-[rgba(255,255,255,0.06)]
                                            py-[15px]  text-white"
                                        >
                                            {item?.FP}
                                        </p>
                                        <p
                                            className="flex w-3/12 items-center justify-center
                                            
                                            border-l-[1px] border-dashed border-[rgba(255,255,255,0.06)]
                                            py-[15px]  text-white"
                                        >
                                            {item?.AC}
                                        </p>
                                    </div>
                                ) : (
                                    <div className=" flex w-[calc(100%-200px)]  items-center  xl:w-[calc(100%-250px)]">
                                        <div className="flex w-3/12 items-center justify-center">
                                            {item?.fundednext}
                                        </div>
                                        <div className="flex w-3/12 items-center justify-center ">
                                            {item?.FTMO}
                                        </div>
                                        <div className="flex w-3/12 items-center justify-center">
                                            {item?.FP}
                                        </div>
                                        <div className="flex w-3/12 items-center justify-center">
                                            {item?.AC}
                                        </div>
                                    </div>
                                )}

                                {index !== 0 && (
                                    <div className="  absolute bottom-[0px] left-0 h-[1px] w-full bg-gradient-to-r from-[rgba(255,255,255,0.02)] via-[rgba(255,255,255,0.06)] to-[rgba(255,255,255,0.02)]" />
                                )}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };
    const renderSmallScreen = () => {
        return (
            <>
                <div className="relative flex pt-[60px] lg:hidden">
                    <div className="absolute top-[115px] h-[0.5px] w-full bg-[rgba(255,255,255,0.15)]"></div>
                    <div className="flex w-[170px] flex-col gap-y-4 rounded-[16px] bg-[#241539] py-5 ">
                        {compareInfo.map((item, index) => {
                            return (
                                <p
                                    className={`w-full  ${index === 0 ? "py-[6px] text-[12px]" : "text-sm"}    px-5 text-white`}
                                    key={index}
                                >
                                    {item?.variable}
                                </p>
                            );
                        })}
                    </div>
                    <div className=" hide-scroll-bar flex  w-[calc(100%-170px)] overflow-x-scroll  px-5 py-5">
                        <div className="flex w-[800px]">
                            <div className="relative flex w-[200px]  flex-col gap-y-4 rounded-[16px]">
                                {compareInfo.map((item, index) =>
                                    renderSingleColMobile(
                                        item?.fundednext,
                                        index
                                    )
                                )}
                            </div>
                            <div className=" flex w-[200px]  flex-col gap-y-4   rounded-[16px]  ">
                                {compareInfo.map((item, index) =>
                                    renderSingleColMobile(
                                        item?.FTMO,
                                        index,
                                        "borderTrue"
                                    )
                                )}
                            </div>
                            <div className="flex w-[200px] flex-col  gap-y-4 rounded-[16px] ">
                                {compareInfo.map((item, index) =>
                                    renderSingleColMobile(
                                        item?.FP,
                                        index,
                                        "borderTrue"
                                    )
                                )}
                            </div>
                            ``
                            <div className="flex w-[200px] flex-col  gap-y-4 rounded-[16px] ">
                                {compareInfo.map((item, index) =>
                                    renderSingleColMobile(
                                        item?.AC,
                                        index,
                                        "borderTrue"
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            <section className="py-[60px] xl:py-[120px]">
                <div className="container-v2">
                    <div className="mb-4 flex justify-center">
                        <AnimatedCapsule
                            text="Compare Markets"
                            bgColor={"#160829"}
                        />
                    </div>
                    <TitleHome content={titleInfo} />
                    {renderLargeScreen()}
                    {renderSmallScreen()}
                </div>
            </section>
        </>
    );
};

export default Compare;
