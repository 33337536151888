import { useGSAP } from "@gsap/react";
import { YouTubeEmbed } from "@next/third-parties/google";
import gsap from "gsap";
import { useRef, useState } from "react";
import CelebrityData from "../../../../data/celebrityData";
import ModalUpdated from "../../common/modalUpdated";
import AnimatedCapsule from "../../common/ui/AnimatedCapsule";
import CelebrityCard from "../../common/ui/CelebrityCard";
// const DynamicModal = dynamic(() => import("../../common/ModalV2"));

const Celebrity = () => {
    const [isModalActive, setIsModalActive] = useState(false);
    const [currentCelebrity, setCurrentCelebrity] = useState(null);
    const container = useRef(null);
    const cardBox = useRef(null);
    const sectionContainer = useRef(null);
    useGSAP(
        () => {
            setTimeout(() => {
                const pad = gsap.matchMedia();
                pad.add("(min-width: 992px)", () => {
                    gsap.to(".text-box", {
                        yPercent: -30,
                        opacity: 0,
                        duration: 0.5,
                        scrollTrigger: {
                            trigger: container.current,
                            start: "top 15%",
                            end: "top 15%",
                            toggleActions: "play none none reverse",
                            onEnter: () => {
                                gsap.to(".left-grad", {
                                    opacity: 1,
                                    duration: 0.5
                                });
                            },
                            onEnterBack: () => {
                                gsap.to(".left-grad", {
                                    opacity: 0,
                                    duration: 0.5
                                });
                            }
                        }
                    });
                    gsap.to(cardBox?.current, {
                        x: -Math.abs(
                            container?.current?.offsetWidth -
                                cardBox?.current?.offsetWidth -
                                cardBox?.current?.getBoundingClientRect().left -
                                40
                        ),
                        scrollTrigger: {
                            trigger: container?.current,
                            start: "top 15%",
                            end: "bottom 15%",
                            pin: true,
                            scrub: 2
                        }
                    });
                });
            }, 300);
        },
        { scope: sectionContainer }
    );

    const handleModalActive = (currentCelebrityObj) => {
        setCurrentCelebrity(currentCelebrityObj);
        setIsModalActive(true);
    };
    const handleModalClose = () => {
        setCurrentCelebrity(null);
        setIsModalActive(false);
    };
    return (
        <>
            <ModalUpdated
                visible={isModalActive}
                handleClose={handleModalClose}
            >
                <YouTubeEmbed
                    videoid={currentCelebrity?.video}
                    width={"100%"}
                    className="w-full"
                    params="controls=0"
                />
            </ModalUpdated>
            <section
                className="w-screen overflow-hidden"
                ref={sectionContainer}
            >
                <div
                    ref={container}
                    className="relative mx-auto overflow-hidden pl-5 md:pl-[75px] lg:max-w-[1004px]  lg:translate-x-[50px] lg:pl-0 xl:max-w-[1612px] xl:translate-x-[115px] 2xl:translate-x-[200px]"
                >
                    <div
                        className="absolute right-[-50%] z-[999] hidden h-[467px] w-[442px] xl:right-0 xl:top-[50px] xl:block "
                        style={{
                            background:
                                "linear-gradient(270deg, #160829 32.65%, rgba(22, 8, 41, 0.00) 100%)"
                        }}
                    />
                    <div
                        className="left-grad pointer-events-none absolute left-[-400px] z-[999] hidden h-[467px] w-[440px] opacity-0 xl:left-[-140px] xl:top-[50px] xl:block"
                        style={{
                            background:
                                "linear-gradient(to right, #160829 32.65%, rgba(22, 8, 41, 0.00) 100%)"
                        }}
                    />
                    <div className="flex flex-col justify-between gap-[50px] pb-[100px] pad:h-[690px] pad:flex-row pad:items-center pad:gap-[40px] tab:gap-[80px]">
                        <div className="text-box shrink-0 pr-[5%]">
                            <div className="pad:max-w-[324px]">
                                <AnimatedCapsule
                                    text="Stars Talk"
                                    bgColor="#160829"
                                />
                                <h2 className="title-60 mt-4 font-Rebond font-bold leading-[100%] text-white">
                                    Icons Around the World
                                </h2>
                                <p className="mt-7 font-Inter text-white/60">
                                    Global leaders are cheering for and
                                    supporting FundedNext. Now, it is your turn
                                    to be a part of the best trading challenge.
                                </p>
                            </div>
                        </div>
                        <div className="hide-scroll-bar shrink-0 max-[992px]:overflow-x-scroll pad:w-max">
                            <div
                                className="flex h-auto gap-[40px] mobile:gap-[80px] pad:h-[400px]"
                                ref={cardBox}
                            >
                                {CelebrityData.map((celebrity, index) => (
                                    <CelebrityCard
                                        celebrity={celebrity}
                                        key={celebrity.id}
                                        activeModalCallback={handleModalActive}
                                        index={index}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Celebrity;
