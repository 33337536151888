import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import PlanPricingTable from "../PlanPage/PricingTable";
import PricingTableMobile from "../PlanPage/PricingTableMobile";
import TitleHome from "./TitleHome";

const ChoosePlan = () => {
    const pathname = usePathname();

    const [active, setActive] = useState("stellar");

    const colors = [
        {
            name: "stellar",
            // color: "linear-gradient(180deg, #530681 0%, #160829 52.66%)"
            color: "linear-gradient(180deg, #3C0665 0%, #160829 100%, #100A28 100%)"
        },
        {
            name: "evaluation",
            color: "linear-gradient(180deg, #392385 1000%, #31075B 52.66%)"
        },
        {
            name: "express",
            color: "linear-gradient(180deg, #0B3D89 0%, #040A36 52.66%)"
        },
        {
            name: "stellar-lite",
            color: "linear-gradient(180deg, #3D4177 0%, #1E1E33 52.66%)"
        }
    ];

    const titleInfo = {
        button: {
            btn_title: "Start Challenge Now",
            btn_link: "/about-us"
        },
        title: {
            text: "Choose the Best Plan",
            customStyle: ""
        },
        description: {
            text: "",
            customStyle: " lg:w-8/12 mx-auto"
        },
        customStyle: "lg:w-10/12 mx-auto"
    };

    useEffect(() => {
        switch (pathname) {
            case "/plan/stellar":
                setActive("stellar");
                break;
            case "/plan/evaluation":
                setActive("evaluation");
                break;
            case "/plan/express":
                setActive("express");
                break;
            case "/plan/stellar-lite":
                setActive("stellar-lite");
                break;
            default:
                setActive("stellar");
        }
    }, [ChoosePlan?.planName]);
    return (
        <div
            id="choose-plan"
            className="rounded-rt-[40px] relative z-0 pt-[60px] transition duration-500 ease-in-out"
        >
            <div className="container-v2"></div>
            {colors.map((color, index) => (
                <div
                    className={`absolute left-0 top-0 z-[-1] h-full w-full rounded-t-[40px] ${color.name === active ? "bg-transition-active" : "bg-transition-inactive"}`}
                    key={"plan-grad-bg-" + index}
                    style={{
                        backgroundImage: color.color
                    }}
                ></div>
            ))}

            <div
                className="absolute left-[50%] top-0 z-[-1] h-full w-[921px] translate-x-[-50%]"
                style={{
                    backgroundImage:
                        "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
                    filter: "blur(367px)",
                    borderRadius: "50%"
                }}
            ></div>
            <div className="z-1 relative">
                <TitleHome content={titleInfo} />
                <div className="mb-[30px] mt-[10px] flex items-center justify-center ">
                    <Link
                        href="/package-comparison"
                        className="md:hidden"
                        target="_blank"
                    >
                        <button className="text-[14px] font-semibold text-white/60 underline">
                            Compare Challenges
                        </button>
                    </Link>
                </div>
                <PlanPricingTable styles="hidden sm:block" />
                <PricingTableMobile styles="sm:hidden" />
            </div>
        </div>
    );
};

export default ChoosePlan;
